import { ApiError } from 'models/api';
import { useDispatch } from 'react-redux';
import { rootActions } from 'store/root.actions';

const currentState = {
  numberOfActiveRequests: 0,
};

export const useFetch = () => {
  const dispatch = useDispatch();

  const addRequest = () => {
    if (currentState.numberOfActiveRequests == 0) {
      dispatch(rootActions.setLoading(true));
    }

    currentState.numberOfActiveRequests++;
  };

  const removeRequest = () => {
    if (currentState.numberOfActiveRequests > 0) {
      currentState.numberOfActiveRequests--;
      if (currentState.numberOfActiveRequests == 0) {
        dispatch(rootActions.setLoading(false));
      }
    }
  };

  const fetchData = <K>(
    url: string,
    method: HttpMethod = HttpMethod.GET,
    body?: string,
    headers?: HeadersInit,
  ): Promise<K> => {
    const envHeaderName = window.__RUNTIME_CONFIG__.REACT_APP_ENVIRONMENT_HEADER_NAME;
    const envHeaderValue = window.__RUNTIME_CONFIG__.REACT_APP_ENVIRONMENT_HEADER_VALUE;

    if (envHeaderName && envHeaderValue) {
      headers = { [`${envHeaderName}`]: envHeaderValue, ...headers };
    }

    const contentTypeKey = 'Content-Type';
    const requestOptions = {
      body,
      method: method.toString(),
      headers: { [contentTypeKey]: 'application/json', ...headers },
    };

    addRequest();

    return fetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json() as Promise<K>;
        }

        return response.text().then((text) => {
          const errorJson = JSON.parse(text);
          const apiError = errorJson as ApiError;

          if (apiError?.message) {
            throw new Error(apiError.message);
          }

          if (apiError?.errors) {
            const errorProperties = Object.keys(apiError.errors);
            const allErrors = errorProperties.map((x) => `${x}: ${apiError.errors[x]}`);
            throw new Error(allErrors.join('\r\n'));
          }

          throw new Error(response.statusText ?? 'Unknown error occurred');
        });
      })
      .finally(() => {
        removeRequest();
      });
  };

  return { fetchData };
};

export enum HttpMethod {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}
