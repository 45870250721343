import { HomePage, TempEmailMessagePage } from 'pages';
import { Navigate } from 'react-router';
import { navigationConstants, navigationParameters } from './navigationConstants';

export interface IRouteInfo {
  path: string;
  isPrivate: boolean;
  component: React.ReactNode;
  menuItemLocalizationKey?: string;
  subPages?: string[];
}

export const getRoutes = (): IRouteInfo[] => {
  return [
    {
      path: navigationConstants.homePage,
      isPrivate: true,
      component: <HomePage />,
    },
    {
      path: `${navigationConstants.mailPage}/:${navigationParameters.emailIdParam}`,
      isPrivate: true,
      component: <HomePage />,
    },
    {
      path: `${navigationConstants.mailPage}/:${navigationParameters.emailIdParam}${navigationConstants.viewSubPath}/:${navigationParameters.messageIdParam}`,
      isPrivate: true,
      component: <TempEmailMessagePage />,
    },
    {
      path: '*',
      isPrivate: true,
      component: <Navigate to={navigationConstants.homePage} />,
    },
  ];
};
