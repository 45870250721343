import CheckIcon from '@mui/icons-material/Check';
import LanguageIcon from '@mui/icons-material/Language';
import { IconButton, Menu, MenuItem, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SupportedLocales } from 'store/reducers/settings.reducer';
import { rootActions } from 'store/root.actions';

interface ILocale {
  locale: SupportedLocales;
  title: string;
}

export const LanguageBar = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const settingsReducer = useSelector((store: RootReducer) => store.settingsReducer);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const locales: Array<ILocale> = [
    {
      locale: 'enUS',
      title: t('languageBarEnglish'),
    },
    {
      locale: 'nlNL',
      title: t('languageBarDutch'),
    },
  ];

  const currentLocale = locales.find((x) => x.locale === settingsReducer.locale);
  const [locale, setLocale] = React.useState<ILocale>(currentLocale ?? locales[0]);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onLanguageMenuItemChange = (language: ILocale) => {
    setLocale(language);
    dispatch(rootActions.setLanguage(language.locale));
  };

  const isSelectedLocale = (language: ILocale) => {
    return locale.locale === language.locale;
  };

  return (
    <Stack direction={'row'} sx={{ alignItems: 'center' }}>
      <IconButton color='inherit' aria-label='open drawer' edge='start' onClick={handleMenuClick}>
        <LanguageIcon color='action' />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        disableScrollLock={true}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {locales.map((language, index) => {
          return (
            <MenuItem value={language.locale} key={index} onClick={() => onLanguageMenuItemChange(language)}>
              {language.title}
              {isSelectedLocale(language) && <CheckIcon sx={{ width: 10, height: 10, ml: 1 }} />}
            </MenuItem>
          );
        })}
      </Menu>
    </Stack>
  );
};
