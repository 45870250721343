import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { EnhancedTable } from 'components/table/EnhancedTable';
import { HeadCell } from 'components/table/EnhancedTableHead';
import { getLocalDateString } from 'functions/helpers';
import { useSmtpApi } from 'hooks/api';
import { TempEmail, TempEmailMessageInfo } from 'models/api';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { navigationConstants, navigationParameters } from 'routes/navigationConstants';
import { rootActions } from 'store/root.actions';

export const HomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const applicationReducer = useSelector((store: RootReducer) => store.applicationReducer);
  const { createTempEmail, getTempEmail } = useSmtpApi();
  const refreshRef = useRef<HTMLButtonElement>(null);
  const lastRefreshUpdateRef = useRef<Date>(new Date());
  const tempEmailId = params[navigationParameters.emailIdParam];

  const headCells: HeadCell<TempEmailMessageInfo>[] = [
    { id: 'fromAddress', label: t('columnFromAddress'), hideOnSize: 'sm' },
    { id: 'fromDisplayName', label: t('columnFromDisplayName'), hideOnSize: 'md' },
    { id: 'subject', label: t('columnSubject') },
  ];

  const [tempEmail, setTempEmail] = useState<TempEmail>();
  const [refreshProgress, setRefreshProgress] = useState<number>(0);
  const refreshRate = window.__RUNTIME_CONFIG__.REACT_APP_ENVIRONMENT_REFRESH_RATE;

  useEffect(() => {
    const interval = setInterval(() => {
      const refreshAge = new Date().getTime() - lastRefreshUpdateRef.current.getTime();
      if (refreshAge >= refreshRate) {
        if (refreshRef?.current) {
          refreshRef.current.click();
        }
      } else {
        const progress = (refreshAge * 100) / refreshRate;
        setRefreshProgress(progress);
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!tempEmailId) {
      createNewTempEmail();
    }
  }, []);

  useEffect(() => {
    if (tempEmailId) {
      loadTempEmail();
    }
  }, [tempEmailId]);

  const createNewTempEmail = () => {
    createTempEmail()
      .then((response) => {
        navigate(`${navigationConstants.mailPage}/${response.tempEmailId}`);
      })
      .catch((error) => {
        dispatch(rootActions.showNotification({ message: error.message, severity: 'error' }));
      });
  };

  const loadTempEmail = () => {
    lastRefreshUpdateRef.current = new Date();
    setRefreshProgress(0);
    if (tempEmailId) {
      getTempEmail(tempEmailId)
        .then((response) => {
          setTempEmail(response.tempEmail);
        })
        .catch(() => {
          // If we have an issue during loading an email by ID (expired, broken email) - let's recreate it
          createNewTempEmail();
        });
    }
  };

  const openEmail = (tempEmailMessage: TempEmailMessageInfo) => {
    if (tempEmail) {
      const path = `${navigationConstants.mailPage}/${tempEmail.id}${navigationConstants.viewSubPath}/${tempEmailMessage.id}`;
      navigate(path);
    }
  };

  return (
    <React.Fragment>
      <Stack>
        <Card elevation={3} sx={{ alignContent: 'center', alignItems: 'center' }}>
          <CardHeader
            title={
              <Box component='span' sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography gutterBottom variant='h6' sx={{ margin: 0, ml: 1 }}>
                  {t('tempEmailComponentTitle')}
                </Typography>
                <Grid container justifyContent='flex-end'>
                  <LoadingButton
                    startIcon={<AddIcon />}
                    loading={applicationReducer.isLoading}
                    type='submit'
                    variant='contained'
                    loadingPosition='start'
                    onClick={createNewTempEmail}
                  >
                    {t('buttonCreateNew')}
                  </LoadingButton>
                </Grid>
              </Box>
            }
          />
          <Divider />
          <CardContent sx={{ margin: 0, padding: 0, pl: 2, pr: 2, pt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  data-testid='tempEmailAddressTextField'
                  fullWidth
                  value={tempEmail?.address ?? t('labelLoading')}
                  InputProps={{
                    readOnly: true,
                  }}
                  label={t('labelYourTempEmail')}
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  data-testid='tempEmailIdTextField'
                  fullWidth
                  value={tempEmail?.id ?? t('labelLoading')}
                  InputProps={{
                    readOnly: true,
                  }}
                  label={t('labelYourTempEmailId')}
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  value={getLocalDateString(tempEmail?.createdOn) ?? t('labelLoading')}
                  InputProps={{
                    readOnly: true,
                  }}
                  label={t('labelTempEmailCreatedOn')}
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  value={getLocalDateString(tempEmail?.expiredOn) ?? t('labelLoading')}
                  InputProps={{
                    readOnly: true,
                  }}
                  label={t('labelTempEmailExpiredOn')}
                  variant='outlined'
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card elevation={3} sx={{ alignContent: 'center', alignItems: 'center', mt: { xs: 2, sm: 4 } }}>
          <CardHeader
            title={
              <Box component='span' sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography gutterBottom variant='h6' sx={{ margin: 0, ml: 1 }}>
                  {t('messagesComponentTitle')}
                </Typography>
                <Grid container justifyContent='flex-end'>
                  <LoadingButton
                    startIcon={
                      <CircularProgress
                        variant='determinate'
                        size={20}
                        sx={{ color: 'white' }}
                        value={refreshProgress}
                      />
                    }
                    loading={applicationReducer.isLoading}
                    ref={refreshRef}
                    variant='contained'
                    loadingPosition='start'
                    onClick={loadTempEmail}
                  >
                    {t('buttonRefresh')}
                  </LoadingButton>
                </Grid>
              </Box>
            }
          />
          <Divider />
          <CardContent sx={{ margin: 0, padding: 0, pl: 2, pr: 2, pt: 1 }}>
            <EnhancedTable rows={tempEmail?.messages ?? []} headCells={headCells} onItemClick={openEmail} />
          </CardContent>
        </Card>

        <Card elevation={3} sx={{ alignContent: 'center', alignItems: 'center', mt: { xs: 2, sm: 4 } }}>
          <CardHeader
            title={
              <Typography gutterBottom variant='h6' sx={{ margin: 0, ml: 1 }}>
                {t('aboutTitle')}
              </Typography>
            }
          />
          <Divider />
          <CardContent sx={{ margin: 0, padding: 0, pl: 2, pr: 2, pt: 1 }}>
            <Box sx={{ margin: 0, ml: 1 }}>
              <Typography sx={{ mt: 1, fontWeight: 'bold' }}>{t('disposableEmailTitle')}</Typography>
              <Typography sx={{ mt: 2, mr: { xs: 1, sm: 3 }, ml: { xs: 0, sm: 2 } }} align='justify'>
                {t('disposableEmailContent1')}
              </Typography>
              <Typography sx={{ mt: 2, mr: { xs: 1, sm: 3 }, ml: { xs: 0, sm: 2 } }} align='justify'>
                {t('disposableEmailContent2')}
              </Typography>

              <Typography sx={{ mt: 1, fontWeight: 'bold' }}>{t('apiTitle')}</Typography>
              <Typography sx={{ mt: 2, mr: { xs: 1, sm: 3 }, ml: { xs: 0, sm: 2 } }} align='justify'>
                {t('apiTitleContent')}
              </Typography>
              <Link color='primary' target='_blank' href='https://smtp.tempmail.xtralab.nl/swagger'>
                {' '}
                <Typography sx={{ mt: 1, mr: { xs: 1, sm: 3 }, ml: { xs: 0, sm: 2 }, fontWeight: 'bold' }}>
                  https://smtp.tempmail.xtralab.nl/swagger
                </Typography>
              </Link>
            </Box>
          </CardContent>
        </Card>
      </Stack>
    </React.Fragment>
  );
};
