import EmailIcon from '@mui/icons-material/Email';
import { AppBar, Box, Container, Toolbar, Typography } from '@mui/material';
import { LanguageBar } from 'components/language/LanguageBar';
import { navigationConstants } from 'routes/navigationConstants';

export const MainToolbar = () => {
  return (
    <AppBar
      position='static'
      elevation={26}
      sx={{
        width: '100%',
        background: 'white',
      }}
    >
      <Container maxWidth='lg'>
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: 'flex' }, flexGrow: 1, alignItems: 'center' }}>
            <EmailIcon sx={{ mr: 1 }} color='primary' />
            <Typography
              variant='h6'
              color='primary'
              noWrap
              component='a'
              href={navigationConstants.homePage}
              sx={{
                mr: 2,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.2rem',
                textDecoration: 'none',
                display: { xs: 'flex' },
              }}
            >
              TEMP MAIL
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <LanguageBar />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
