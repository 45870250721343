import * as locales from '@mui/material/locale';
import { getType } from 'typesafe-actions';
import { rootActions } from '../root.actions';

export type SupportedLocales = keyof typeof locales;

export interface ISettingsState {
  locale: SupportedLocales;
}

const setInitState = () => ({
  locale: 'enUS' as SupportedLocales,
});

export const settingsReducer: import('redux').Reducer<ISettingsState> = (
  state: ISettingsState = setInitState(),
  action: RootActions,
) => {
  switch (action.type) {
    case getType(rootActions.setLanguage):
      return {
        ...state,
        locale: action.payload,
      };
    default:
      return state;
  }
};
