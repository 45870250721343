import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingButton } from '@mui/lab';
import { Box, Card, CardContent, CardHeader, Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import { EnhancedTable } from 'components/table/EnhancedTable';
import { HeadCell } from 'components/table/EnhancedTableHead';
import { getLocalDateString } from 'functions/helpers';
import { useSmtpApi } from 'hooks/api';
import { TempEmailAttachmentInfo } from 'models/api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { navigationConstants, navigationParameters } from 'routes/navigationConstants';
import { rootActions } from 'store/root.actions';
import { TempEmailMessage } from '../models/api/tempEmail/message/TempEmailMessage';

export const TempEmailMessagePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const applicationReducer = useSelector((store: RootReducer) => store.applicationReducer);
  const { getTempEmailMessage, getTempEmailAttachment } = useSmtpApi();

  const params = useParams();
  const navigate = useNavigate();
  const tempEmailId = params[navigationParameters.emailIdParam];
  const messageId = params[navigationParameters.messageIdParam];

  const [tempEmailMessage, setTempEmailMessage] = useState<TempEmailMessage>();

  const headCells: HeadCell<TempEmailAttachmentInfo>[] = [
    { id: 'name', label: t('columnName'), hideOnSize: 'xs' },
    { id: 'mediaType', label: t('columnMediaType'), hideOnSize: 'sm' },
  ];

  const openEmailAttachment = (attachmentInfo: TempEmailAttachmentInfo) => {
    if (tempEmailId && messageId) {
      getTempEmailAttachment(tempEmailId, messageId, attachmentInfo.id)
        .then((response) => {
          const fetchDataModified = `data:${response.tempEmailAttachment.mediaType};base64,${response.tempEmailAttachment.contentBase64String}`;
          const link = document.createElement('a');
          link.href = fetchDataModified;
          link.download = response.tempEmailAttachment.name;
          link.click();
        })
        .catch((error) => {
          dispatch(rootActions.showNotification({ message: error.message, severity: 'error' }));
        });
    }
  };

  const createMessageBodyMarkup = () => {
    return { __html: tempEmailMessage?.body ?? '' };
  };

  useEffect(() => {
    if (!tempEmailId) {
      navigate(navigationConstants.homePage);
      return;
    }

    if (messageId) {
      getTempEmailMessage(tempEmailId, messageId)
        .then((response) => {
          setTempEmailMessage(response.tempEmailMessage);
        })
        .catch(() => {
          goBackToEmail();
        });
    } else {
      goBackToEmail();
    }
  }, []);

  const goBackToEmail = () => {
    navigate(`${navigationConstants.mailPage}/${tempEmailId}`);
  };

  return (
    <Stack>
      <Card elevation={3} sx={{ alignContent: 'center', alignItems: 'center' }}>
        <CardHeader
          title={
            <Box component='span' sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography gutterBottom variant='h6' sx={{ margin: 0, ml: 1 }}>
                {t('messageComponentTitle')}
              </Typography>
              <Grid container justifyContent='flex-end'>
                <LoadingButton
                  startIcon={<ArrowBackIcon />}
                  loading={applicationReducer.isLoading}
                  variant='contained'
                  loadingPosition='start'
                  onClick={goBackToEmail}
                >
                  {t('buttonBack')}
                </LoadingButton>
              </Grid>
            </Box>
          }
        />
        <Divider />
        <CardContent sx={{ margin: 0, padding: 0, pl: 2, pr: 2, pt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                value={tempEmailMessage?.fromAddress ?? t('labelLoading')}
                InputProps={{
                  readOnly: true,
                }}
                label={t('labelFromAddress')}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                value={tempEmailMessage?.fromDisplayName ?? t('labelLoading')}
                InputProps={{
                  readOnly: true,
                }}
                label={t('labelFromDisplayName')}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                value={tempEmailMessage?.subject ?? t('labelLoading')}
                InputProps={{
                  readOnly: true,
                }}
                label={t('labelSubject')}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                value={getLocalDateString(tempEmailMessage?.date) ?? t('labelLoading')}
                InputProps={{
                  readOnly: true,
                }}
                label={t('labelDate')}
                variant='outlined'
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card elevation={3} sx={{ alignContent: 'center', alignItems: 'center', mt: { xs: 2, sm: 4 } }}>
        <CardHeader
          title={
            <Box component='span' sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography gutterBottom variant='h6' sx={{ margin: 0, ml: 1 }}>
                {t('messageBodyComponentTitle')}
              </Typography>
            </Box>
          }
        />
        <Divider />
        <CardContent sx={{ margin: 0, padding: 0, pl: 2, pr: 2, pt: 3, overflowX: 'auto' }}>
          <Grid sx={{ m: 2 }}>
            <div dangerouslySetInnerHTML={createMessageBodyMarkup()} />
          </Grid>
        </CardContent>
      </Card>

      <Card elevation={3} sx={{ alignContent: 'center', alignItems: 'center', mt: { xs: 2, sm: 4 } }}>
        <CardHeader
          title={
            <Box component='span' sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography gutterBottom variant='h6' sx={{ margin: 0, ml: 1 }}>
                {t('attachmentComponentTitle')}
              </Typography>
            </Box>
          }
        />
        <Divider />
        <CardContent sx={{ margin: 0, padding: 0, pl: 2, pr: 2, pt: 1 }}>
          <EnhancedTable
            rows={tempEmailMessage?.attachments ?? []}
            headCells={headCells}
            onItemClick={openEmailAttachment}
          />
        </CardContent>
      </Card>
    </Stack>
  );
};
