import { createTheme } from '@mui/material/styles';
import { colors } from './colors';

export const getTheme = () => {
  const theme = createTheme({
    // components: {
    //   MuiOutlinedInput: {
    //     styleOverrides: {
    //       input: {
    //         '& input:-webkit-autofill': {
    //           '-webkit-box-shadow': '0 0 0 100px var(--primary-weak) inset',
    //           '-webkit-text-fill-color': 'var(--text-primary)',
    //           transition: 'background-color 5000s ease-in-out 0s',
    //         },
    //       },
    //     },
    //   },
    // },
    palette: {
      primary: {
        main: colors.primaryMainColor,
      },
      text: {
        primary: colors.primaryTextColor,
      },
      action: {
        selected: colors.actionSelectedColor,
        // hover: colors.actionHoverColor,
      },
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      fontSize: 12,
      body1: {
        color: colors.primaryTextColor,
      },
      h1: {
        color: colors.primaryTextColor,
      },
    },
  });

  theme.shadows.push(
    '0px 3px 5px -1px rgba(0,0,0,0.07),0px 6px 10px 0px rgba(0,0,0,0.07),0px 1px 18px 0px rgba(0,0,0,0.12)',
  );

  theme.shadows.push(
    '0px 3px 5px -1px rgba(0,0,0,0.03),0px 1px 1px 0px rgba(0,0,0,0.03),0px 1px 12px 0px rgba(0,0,0,0.12)',
  );

  return theme;
};
