import { createRoot } from 'react-dom/client';
import { App } from './App';
import './i18n/config';
import './index.css';

const container = document.getElementById('root');
if (!container) {
  throw Error(`Can't find root`);
}
const root = createRoot(container);
root.render(<App />);
