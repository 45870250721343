import { ConfirmationModalContextProvider } from 'providers/ConfirmationModalContextProvider';
import { WithPersistentStore } from 'store/WithPersistentStore';
import { WithTheme } from 'theme/WithTheme';

export const App = () => {
  return (
    <ConfirmationModalContextProvider>
      <WithPersistentStore>
        <WithTheme />
      </WithPersistentStore>
    </ConfirmationModalContextProvider>
  );
};
