import { Alert, Snackbar, SnackbarCloseReason } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { rootActions } from 'store/root.actions';

export const Notifier = () => {
  const applicationReducer = useSelector((store: RootReducer) => store.applicationReducer);
  const dispatch = useDispatch();

  const handleClose = (event: Event | React.SyntheticEvent<unknown>, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(rootActions.hideNotification());
  };

  return (
    <Snackbar
      open={applicationReducer.showNotification}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        elevation={3}
        variant='filled'
        onClose={handleClose}
        severity={applicationReducer.notificationInfo?.severity}
        sx={{ width: '100%' }}
      >
        {applicationReducer.notificationInfo?.message}
      </Alert>
    </Snackbar>
  );
};
