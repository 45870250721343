import { HttpMethod, useFetch } from 'hooks/api/useFetch';
import {
  CreateTempEmailResponse,
  GetTempEmailAttachmentResponse,
  GetTempEmailMessageResponse,
  GetTempEmailResponse,
} from 'models/api';

export const useSmtpApi = () => {
  const endpoint = window.__RUNTIME_CONFIG__.REACT_APP_SMTP_API;
  const { fetchData } = useFetch();

  const createTempEmail = (): Promise<CreateTempEmailResponse> => {
    const url = `${endpoint}/api/tempemail`;
    return fetchData<CreateTempEmailResponse>(url, HttpMethod.POST);
  };

  const getTempEmail = (tempEmailId: string): Promise<GetTempEmailResponse> => {
    const url = `${endpoint}/api/tempemail/${tempEmailId}`;
    return fetchData<GetTempEmailResponse>(url, HttpMethod.GET);
  };

  const getTempEmailMessage = (
    tempEmailId: string,
    tempEmailMessageId: string,
  ): Promise<GetTempEmailMessageResponse> => {
    const url = `${endpoint}/api/tempemail/${tempEmailId}/${tempEmailMessageId}`;
    return fetchData<GetTempEmailMessageResponse>(url, HttpMethod.GET);
  };

  const getTempEmailAttachment = (
    tempEmailId: string,
    tempEmailMessageId: string,
    tempEmailAttachmentId: string,
  ): Promise<GetTempEmailAttachmentResponse> => {
    const url = `${endpoint}/api/tempemail/${tempEmailId}/${tempEmailMessageId}/${tempEmailAttachmentId}`;
    return fetchData<GetTempEmailAttachmentResponse>(url, HttpMethod.GET);
  };

  return { createTempEmail, getTempEmail, getTempEmailMessage, getTempEmailAttachment };
};
