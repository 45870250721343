import { Container, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { Notifier } from 'components/notifier/Notifier';
import { MainToolbar } from 'components/toolbar/MainToolbar';
import { Outlet } from 'react-router';

export const AppLayout = () => {
  return (
    <Stack>
      <MainToolbar />
      <Container maxWidth='lg'>
        <Box
          sx={{ flexGrow: 1, p: { xs: 2, sm: 4 }, ml: { xs: -2, sm: -4 }, mr: { xs: -2, sm: -4 }, overflowX: 'auto' }}
        >
          <Outlet />
          <Notifier />
        </Box>
      </Container>
    </Stack>
  );
};
