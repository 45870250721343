import { Box, Breakpoint, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { getCellDisplay } from 'functions/helpers';

interface EnhancedTableProps<TableItem> {
  headCells: HeadCell<TableItem>[];
  onRequestSort: (property: keyof TableItem) => void;
  order: Order;
  orderBy: keyof TableItem | undefined;
}

export interface HeadCell<TableItem> {
  id: (string | boolean | number) & keyof TableItem;
  label: string;
  align?: 'left' | 'right';
  hideOnSize?: Breakpoint;
  isValueLocal?: boolean;
}

export type Order = 'asc' | 'desc';

export const EnhancedTableHead = <TableItem,>(props: EnhancedTableProps<TableItem>) => {
  const { order, orderBy, headCells, onRequestSort } = props;

  const createSortHandler = (property: keyof TableItem) => () => {
    onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sx={{
              padding: 1,
              display: getCellDisplay(headCell),
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
