import { HeadCell } from 'components/table/EnhancedTableHead';

export const getCellDisplay = <TableItem>(headCell: HeadCell<TableItem>) => {
  if (headCell.hideOnSize) {
    return {
      xs: 'none',
      [headCell.hideOnSize]: 'table-cell',
    };
  }
};
