import { NotificationInfo } from 'models/notification/NotificationInfo';
import { getType } from 'typesafe-actions';
import { rootActions } from '../root.actions';

export interface IApplicationState {
  isLoading: boolean;
  notificationInfo?: NotificationInfo;
  showNotification: boolean;
}

const setInitState = () => ({
  isLoading: false,
  showNotification: false,
});

export const applicationReducer: import('redux').Reducer<IApplicationState> = (
  state: IApplicationState = setInitState(),
  action: RootActions,
) => {
  switch (action.type) {
    case getType(rootActions.setLoading):
      return {
        ...state,
        isLoading: action.payload,
      };
    case getType(rootActions.showNotification):
      return {
        ...state,
        notificationInfo: action.payload,
        showNotification: true,
      };
    case getType(rootActions.hideNotification):
      return {
        ...state,
        showNotification: false,
      };
    case getType(rootActions.rehydrateAction):
      return {
        ...state,
        isLoading: false,
        notificationInfo: undefined,
        showNotification: false,
      };
    default:
      return state;
  }
};
