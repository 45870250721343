import { AppLayout } from 'layouts';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { getRoutes } from './routes';

export const AppRoutes = () => {
  const routes = getRoutes();

  const privateRouteComponents = routes
    .filter((x) => x.isPrivate)
    .map(({ path, component }, key) => <Route path={path} element={component} key={key} />);

  const publicRouteComponents = routes
    .filter((x) => !x.isPrivate)
    .map(({ path, component }, key) => <Route path={path} element={component} key={key} />);

  return (
    <BrowserRouter>
      <Routes>
        {publicRouteComponents}
        <Route element={<PrivateRoute />}>
          <Route element={<AppLayout />}>{privateRouteComponents}</Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
