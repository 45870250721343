import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useModalShow } from 'hooks/modal/useModalShow';
import React, { useContext, useRef, useState } from 'react';

type ModalContextType = {
  showConfirmation: (title: string, message: string | JSX.Element) => Promise<boolean>;
};

type ConfirmationModalContextProviderProps = {
  children: React.ReactNode;
};

const ConfirmationModalContext = React.createContext<ModalContextType>({} as ModalContextType);

export const ConfirmationModalContextProvider: React.FC<ConfirmationModalContextProviderProps> = (props) => {
  const { setShow, show, onHide } = useModalShow();
  const [content, setContent] = useState<{ title: string; message: string | JSX.Element } | null>();
  const resolver = useRef<(value: boolean | PromiseLike<boolean>) => void>();

  const handleShow = (title: string, message: string | JSX.Element): Promise<boolean> => {
    setContent({
      title,
      message,
    });
    setShow(true);

    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const modalContext: ModalContextType = {
    showConfirmation: handleShow,
  };

  const handleOk = () => {
    resolver.current && resolver.current(true);
    onHide();
  };

  const handleCancel = () => {
    resolver.current && resolver.current(false);
    onHide();
  };

  return (
    <ConfirmationModalContext.Provider value={modalContext}>
      {props.children}

      {content && (
        <Dialog
          open={show}
          onClose={handleCancel}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{content.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>{content.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button data-testid='confirmationCancelButton' onClick={handleCancel}>
              No
            </Button>
            <Button data-testid='confirmationOkButton' onClick={handleOk} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </ConfirmationModalContext.Provider>
  );
};

export const useConfirmationModalContext = (): ModalContextType => useContext(ConfirmationModalContext);
