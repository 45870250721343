import * as locales from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppRoutes } from 'routes/AppRoutes';
import { getTheme } from './theme';

export const WithTheme = () => {
  const theme = getTheme();
  const settingsReducer = useSelector((store: RootReducer) => store.settingsReducer);
  const { i18n } = useTranslation();

  const themeWithLocale = React.useMemo(
    () => createTheme(theme, locales[settingsReducer.locale]),
    [settingsReducer.locale, theme],
  );

  useEffect(() => {
    i18n.changeLanguage(settingsReducer.locale.substring(0, 2));
  }, [settingsReducer.locale]);

  return (
    <ThemeProvider theme={themeWithLocale}>
      <AppRoutes />
    </ThemeProvider>
  );
};
