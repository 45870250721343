export const navigationParameters = {
  messageIdParam: 'messageId',
  emailIdParam: 'emailId',
};

export const navigationConstants = {
  homePage: '/',
  loginPage: `/login`,
  viewSubPath: `/view`,
  mailPage: `/mail`,
};
