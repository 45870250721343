import { Box, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material';
import { getCellDisplay } from 'functions/helpers';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EnhancedTableHead, HeadCell, Order } from './EnhancedTableHead';

interface EnhancedTableProps<TableItem> {
  rows: TableItem[];
  headCells: HeadCell<TableItem>[];
  onItemClick?: (item: TableItem) => void;
}

export const EnhancedTable = <TableItem,>(props: EnhancedTableProps<TableItem>) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof TableItem>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const { t } = useTranslation();
  const { rows, headCells, onItemClick } = props;

  const handleRequestSort = (property: keyof TableItem) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onRowClick = (item: TableItem) => {
    if (onItemClick) {
      onItemClick(item);
    }
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const compare = (a: TableItem, b: TableItem) => {
    return order === 'desc' ? descendingComparator(a, b, orderBy) : -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = <TableItem,>(a: TableItem, b: TableItem, orderBy: keyof TableItem | undefined) => {
    if (orderBy) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }

    return 0;
  };

  const getCelValue = (item: TableItem, headCell: HeadCell<TableItem>) => {
    const value = item[headCell.id];

    if (headCell.isValueLocal) {
      return t(value as string);
    }

    if (typeof value == 'boolean') {
      const localKey = value ? 'columnValueTrue' : 'columnValueFalse';
      return t(localKey);
    }

    return value as string;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer>
        <Table aria-labelledby='tableTitle'>
          <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headCells={headCells} />
          <TableBody>
            {rows
              .sort(compare)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow
                    sx={{ cursor: 'pointer' }}
                    hover
                    tabIndex={-1}
                    key={`TableRow${index}`}
                    onClick={() => onRowClick(row)}
                  >
                    {headCells.map((headCell, cellIndex) => {
                      return (
                        <TableCell
                          sx={{
                            padding: 1.5,
                            whiteSpace: 'nowrap',
                            display: getCellDisplay(headCell),
                          }}
                          key={`TableCell${cellIndex}`}
                          align={headCell.align}
                        >
                          {getCelValue(row, headCell)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 40 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100, 500]}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ ml: { xs: -3, sm: 0 } }}
      />
    </Box>
  );
};
